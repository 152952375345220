import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { CompleteNewPasswordComponent } from '../complete-new-password/complete-new-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateNewPasswordComponent } from './forgot-password/create-new-password/create-new-password.component';
import { ForcePasswordChangeComponent } from './forgot-password/force-password-change/force-password-change.component';
import {RedirectIfLoginGuard} from "../security/redirect.if.login.guard";
import { PasswordResetDoneComponent } from './forgot-password/password-reset-done/password-reset-done.component';
import {VerificationComponent} from "../verification/verification.component";
import {TwoFAVerificationComponent} from "../verification_2fa/verification_2fa.component";
import { SsoAuthorizeComponent } from './forgot-password/sso-authorize/sso-authorize.component';
import {TotpSetupComponent} from "../totp-setup/totp-setup.component";

const routes: Routes = [
    {
      path: 'login',
      component: LoginComponent , canActivate: [RedirectIfLoginGuard]
    },
    {
      path: 'authorize',
      component: SsoAuthorizeComponent , canActivate: [RedirectIfLoginGuard]
    },
    {
      path: 'change-password',
      component: CompleteNewPasswordComponent, canActivate: [RedirectIfLoginGuard]
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent,
    },
    {
      path: 'force-reset-password',
      component: ForcePasswordChangeComponent,
    },
    {
      path: 'create-new-password',
      component: CreateNewPasswordComponent,
    },
    {
      path: 'password-reset-done',
      component: PasswordResetDoneComponent, canActivate: [RedirectIfLoginGuard]
    },
    {
      path: 'verification',
      component: VerificationComponent, canActivate: [RedirectIfLoginGuard]
    },
    {
      path: '2fa-verification',
      component: TwoFAVerificationComponent , canActivate: [RedirectIfLoginGuard]
    },
   {
     path: 'totp-setup',
     component: TotpSetupComponent
   }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
