import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { UserDataManager } from '../user/UserDataManager';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/reducers';

@Injectable()
export class RoleInterceptor implements HttpInterceptor {
  userRole: string = '';
  storeState$: Observable<any>;
  selectedAgencies: string[];
  selectedCompanies: string[];

  constructor(
    private localStorageService: LocalStorageService,
    private userDataManager: UserDataManager,
    private store: Store<AppState>,
  ) {
    this.storeState$ = this.store.pipe(select((state) => state));
    this.storeStateSubscription();

    this.userDataManager.activeAgencyRole$.subscribe((user) => {
      this.userRole = user.role;
    });
  }

  storeStateSubscription() {
    this.storeState$.subscribe((storeState) => {
      this.selectedAgencies =
        storeState && storeState?.agencyState && storeState?.agencyState?.agencies && storeState?.agencyState?.agencies.length
          ? storeState?.agencyState?.agencies
          : [];

      this.selectedCompanies =
        storeState && storeState?.companyState && storeState?.companyState?.companies && storeState?.companyState?.companies.length
          ? storeState?.companyState?.companies
          : [];
    });
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest: HttpRequest<any>;
    if (
      this.userRole ||
      request.url.includes('profile') ||
      request.url.includes('process-esoc-resend-request') ||
      request.url.includes('sso-authorize') ||
      request.url.includes('sso-logout') ||
      request.url.includes('file-validity') ||
      request.url.includes('check-eDoc-link') ||
      request.url.includes('active') ||
      request.url.includes('/patient/heartbeat') ||
      request.url.includes('/user/email') ||
      request.url.includes('get-agency-information') ||
      request.url.includes('process-eph-resend-request') ||
      request.url.includes('get-eph-file-url') ||
      request.url.includes('agency-information') ||
      request.url.includes('updateEsocFileStatus') ||
      request.url.includes('/user/update-registration-status') ||
     // request.url.includes('/auth/generate-totp-secret') ||
      request.url.includes('/user/generate-totp-secret') ||
      request.url.includes('/user/get-totp-secret') ||
      request.url.includes('/user/verify-totp-secret')
    ) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.localStorageService.get('_token'),
          Role: this.userRole,
          selectedAgencies: this.selectedAgencies,
          selectedCompanies: this.selectedCompanies,
        },
      });

      return next.handle(modifiedRequest);
    }

    return undefined;
  }
}
