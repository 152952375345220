import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UserDataManager} from "../../user/UserDataManager";
import {Agency, RoleEnum, Roles, userRoles} from "../../models/agency";
import {
  AGENCY_PATH, COMPANY_ADMIN, CURRENT_RELEASE_VERSION,
  dateFormat,
  DEFAULT_USER_IMAGE, EDOCS, EPISODE_PATH,
  IOH_ADMIN,
  LOGIN_PATH, PVISITS,
  RoleID,
  SUBMISSION_PATH, SUPER_ADMIN, SUPPORT_TICKET, UNMATCHED_VISITS
} from "../../common/Constants";
import {ActivatedRoute, Router} from "@angular/router";
import {BreadcrumbsService} from "../../services/breadcrumbs/breadcrumbs.service";
import {Subscription} from "rxjs";
import {User} from "../../models/user";
import {AgencyService} from "../../services/agency/agency.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {DatePipe, Location} from "@angular/common";
import {PreferencesService} from 'src/app/services/preferences.service';
import {NotificationService} from "../../services/notification.service";
import {notificationMessage, Notifications} from '../../models/notification';
import {ProgressBarService} from "../../services/progress-bar.service";
import {Auth} from "aws-amplify";
import {AuthService} from "../../security/auth.service";
import {UtilService} from "../../services/util/util.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  roles: userRoles[];
  currentUser: User;
  selectedRole: string;
  selectedAgency: string;
  picture: string;
  userName: string;
  firstTime = true;
  totalAgencies: number = 0;
  breadCrumbs: string;
  agencyDetails: Agency[];
  RoleEnum = RoleEnum;
  notifications: Notifications[];
  notificationsCount: number = 0;
  subscriptions: Subscription[] = [];
  dateFormat = dateFormat;
  toRecheck = notificationMessage.TO_RECHECK;
  defaultUserPicture = DEFAULT_USER_IMAGE;
  profileOptions = false;
  currentReleaseVersion = CURRENT_RELEASE_VERSION;
  totpEnabled: boolean = false;
  qrCodeUrl: string;
  @ViewChild('toggleProfile') toggleProfile: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  constructor(public userDataManager: UserDataManager,
              private router : Router,
              private breadcrumbsService: BreadcrumbsService,
              private activatedRoute: ActivatedRoute,
              private agencyService: AgencyService,
              private localStorageService: LocalStorageService,
              private preferencesService : PreferencesService,
              private location: Location,
              private notificationService: NotificationService,
              private readonly progressBarService: ProgressBarService,
              private datePipe: DatePipe,
              private authService : AuthService,
              private renderer: Renderer2,
              private utilService: UtilService,
              private userService: UserService)
  {
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== this.toggleProfile?.nativeElement && e.target !== this.menu?.nativeElement){
        this.profileOptions=false;
      }
    });
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getAgencyRole();
    this.getBreadCrumbs();
    this.setGlobalRole(this.selectedAgency, this.selectedRole);
    this.getNotifications();
    this.getTOTP();
    }

  ngOnDestroy(): void {
    if(this.subscriptions?.length){
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  // get current bread crumbs from breadcrumb service
  getBreadCrumbs() {
    this.subscriptions.push(this.breadcrumbsService.breadcrumbs$.subscribe(res => {
      if(res?.length > 0) {
        this.breadCrumbs = res?.[0]?.label;
      }
    }))
  }

  getUserProfile() {
    this.subscriptions.push(this.userDataManager.user$.subscribe(res => {
        this.currentUser = res;
        this.roles = !res.is_super_admin ? !res.is_IOH_admin ? !res.is_company_admin ? Roles : [{ label: COMPANY_ADMIN, value: RoleID.companyAdmin }]  : [{ label: IOH_ADMIN, value: RoleID.iohAdmin }] : [{ label: SUPER_ADMIN, value: RoleID.Admin }];
        this.picture = res.picture;
        this.totalAgencies = !res.is_super_admin ? !res.is_company_admin ? res.agency_roles.length : null : null;
        this.userName = `${res.firstName}` + (res.lastName ? ` ${res.lastName}` : '');
        this.redirectAccordingToRole(this.currentUser.currentUserRole);
      if(!res.is_super_admin && !res.is_IOH_admin && !res.is_company_admin && this.firstTime && !this.localStorageService.get('currentAgency')) {
        const defaultAgency = res.agency_roles?.find(agency => agency?.defaultAgency);
        this.userDataManager.activeAgencyRole$.next({agency: defaultAgency?.agency?.agencyID, role: res?.defaultRole})
        this.firstTime = false;
      } else if (res.is_super_admin) {
        this.userDataManager.activeAgencyRole$.next({agency: null, role: RoleID.Admin})
      } else if (res.is_IOH_admin) {
        this.userDataManager.activeAgencyRole$.next({agency: null, role: RoleID.iohAdmin})
      } else if (res.is_company_admin) {
        this.selectedAgency = ''
        this.selectedRole = RoleID.companyAdmin
        this.userDataManager.activeAgencyRole$.next({agency: null, role: RoleID.companyAdmin})
      }
    }));
  }
  redirectAccordingToRole(role) {
    if ( role == RoleID.Clinician && this.location.path() == EDOCS) {
      this.router.navigate([SUBMISSION_PATH])
    } else if ( role == RoleID.AgencyAdmin && this.location.path() == SUPPORT_TICKET) {
        this.router.navigate([AGENCY_PATH]);
    } else if((role == RoleID.CaseManager || role == RoleID.Clinician || role == RoleID.OfficeWorker) &&
      (this.location.path() != SUBMISSION_PATH && this.location.path() != EDOCS && this.location.path() != EPISODE_PATH && this.location.path() != PVISITS && this.location.path() != UNMATCHED_VISITS && this.location.path() != SUPPORT_TICKET)) {
      this.router.navigate([SUBMISSION_PATH])
    }
  }

  private getTOTP() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe(res => {
        if(res && res?.totp_secret && res.userSettings?.mfa_token){
          this.userService.getTotpSecret(res.aws_cognito_id).subscribe(response => {
          if(response && response.qrcode_url) {
            this.totpEnabled = true;
            this.qrCodeUrl = response.qrcode_url;
          }
        })}}));
  }

  getAgencyRole() {
    this.subscriptions.push(this.userDataManager.activeAgencyRole$.subscribe(res => {
      if ((this.localStorageService.get('currentAgency') || this.localStorageService.get('currentRole')) &&
        !this.currentUser.is_super_admin) {
        this.selectedAgency = this.localStorageService.get('currentAgency');
        const currentAgency = this.currentUser?.agency_roles?.find(agency_role => agency_role.agency.agencyID == this.selectedAgency);
        const isValidRole = !!currentAgency?.roles?.find(role => role == this.localStorageService.get('currentRole'));

        if (isValidRole) {
          this.selectedRole = this.localStorageService.get('currentRole');
        } else {
          this.selectedAgency = res.agency;
          this.selectedRole = res.role;
        }
      } else {
        this.selectedAgency = res.agency;
        this.selectedRole = res.role;
      }
    }))
  }

  setGlobalRole(agencyID, role) {
    this.localStorageService.set('currentAgency', agencyID);
    this.localStorageService.set('currentRole', role);
    this.userDataManager.activeAgencyRole$.next({agency: agencyID, role: role})
    this.currentUser.currentUserRole = role;
    this.userDataManager.user$.next(this.currentUser);
  }
  openSidebar() {
    this.preferencesService.openSidebar();
  }

  async getNotifications() {
    this.subscriptions.push(await this.notificationService.getNotifications({id: this.currentUser?._id}).subscribe(res => {
        if(res?.success) {
          this.notifications = res?.content?.data;
          this.notificationsCount = res?.content?.totalElements;
        }
    }))
  }

  formatNotificationDate(createdAt) {
    const startDate = new Date(createdAt);
    const endDate = new Date();

    const hoursDifference = this.getHoursDifference(startDate, endDate);

    if(hoursDifference < 1) {
      return 'less than one hour'
    } else if(hoursDifference <= 24) {
      return Math.floor(hoursDifference) + ' hours ago';
    } else {
      return this.datePipe.transform(createdAt, dateFormat);
    }
  }

  getHoursDifference(date1, date2) {
    const diffInMilliseconds = date2 - date1;
    const hoursDifference = diffInMilliseconds / (1000 * 60 * 60);
    return hoursDifference;
  }

   updateNotifications(notification) {
     notification.read = true;

     this.progressBarService.setProgressBarVisibility(true);
     this.subscriptions.push(this.notificationService.updateNotifications([notification]).subscribe(res => {
        if(res.success) {
          this.notifications = [];
          this.notificationsCount = 0;
          this.getNotifications();
        }
       this.progressBarService.setProgressBarVisibility(false);
     }))
  }

  async logOut() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      Auth.signOut().then(() => {
        this.authService.clear()
        //this.userDataManager.activeAgencyRole$.next({agency: '', role: ''});
        this.authService.authenticationSubject.next(false);
        this.utilService.clearStore();
        this.userService.logOutWithMicrosoft().subscribe((data: any) => {
        location.href = data.url;
      })
        this.progressBarService.setProgressBarVisibility(false);
        //this.router.navigate([LOGIN_PATH]);
      });
    }catch (e) {
      console.log(e);
    }
  }
}
