import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginUser, UserStatus } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../security/auth.service';
import { CognitoUserService } from '../services/cognito-user.service';
import {
  CHANGE_PASSWORD_PATH,
  CURRENT_RELEASE_VERSION,
  LOGIN_PATH,
  LOGO_TEXT,
  NEW_PASSWORD_REQUIRED,
  RELOAD_PAGE_AFTER_RELEASE, TOTP_SETUP_PATH, TWOFA_VERIFICATION_PATH,
  USER_DELETED,
  USER_NOT_ACTIVE,
  USER_NOT_FOUND,
  VERIFICATION_PATH,
} from '../common/Constants';
import { UserService } from '../services/user.service';
import { MessageHandlerService } from '../services/message-handler.service';
import { UtilService } from '../services/util/util.service';
import { UserDataManager } from '../user/UserDataManager';
import { Subscription } from 'rxjs';
import {strToLowerCase} from "../helper/functions";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  user: LoginUser;
  loading = false;
  logoText = LOGO_TEXT;
  email = '';
  password = '';
  isAutoPopulate = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private utilService: UtilService,
    private cognitoUserService: CognitoUserService,
    private messageHandlerService: MessageHandlerService,
    private userDataManager: UserDataManager,
    private route: ActivatedRoute,
  ) {
    this.user = {} as LoginUser;
    // this.cognitoUserService = new CognitoUserService;
  }

  ngOnInit(): void {
    this.getReleaseVersion();
    this.autoPopulateEmailAndPassword();
  }

  autoPopulateEmailAndPassword() {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'] ? params['email'].split(' ').join('+') : '';
      this.password = params['password'];
      if (this.email && this.password) {
        this.isAutoPopulate = true;
        this.user.email = this.email;
        this.user.password = this.password;
        this.loginWithCognito();
      }
    });
  }

  loginWithMicrosoft() {
    this.authService.clear();
    this.userDataManager.activeAgencyRole$.next({ agency: '', role: '' });
    this.utilService.clearStore();
    this.subscriptions.push(
      this.userService.loginWithMicrosoft().subscribe((data: any) => {
        location.href = data.url;
      }),
    );
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  loginWithCognito() {
    this.loading = true;
    this.authService.clear();
    // set email to lowercase in user object
    this.user.email = strToLowerCase(this.user.email);
    if (this.user && this.user.email && this.user.password) {
      this.userService.checkExistingEmails(this.user.email).subscribe((response) => {
        if (response.success && response.content.data.length) {
          this.userDataManager.activeAgencyRole$.next({ agency: '', role: '' });
          this.utilService.clearStore();
          const user = response.content.data[0];
          const userMFAStatus = !!user.totp_secret;
          const userTokenMFA = !!user.mfa_token;
          if (user.status === UserStatus.active && !user?.deleted) {
            this.authService
              .signIn(this.user)
              .then((user) => {
                if (user.challengeName === NEW_PASSWORD_REQUIRED) {
                  this.cognitoUserService.cognitoUser = user;

                  if (this.isAutoPopulate) {
                    const queryParams = {
                      password: this.password,
                    };
                    this.router.navigate([CHANGE_PASSWORD_PATH], { queryParams });
                  } else {
                    this.router.navigate([CHANGE_PASSWORD_PATH]);
                  }

                  this.loading = false;
                } else if (user.challengeName === 'SMS_MFA') {
                  this.cognitoUserService.cognitoUser = user;
                  this.router.navigate([VERIFICATION_PATH]);
                  this.loading = false;

                }else if(user.challengeName === 'SOFTWARE_TOKEN_MFA'){
                  this.cognitoUserService.cognitoUser = user;
                  this.cognitoUserService.currentUser = response.content.data[0];
                  this.router.navigate([TWOFA_VERIFICATION_PATH]);
                  this.loading = false
                }else if (!userMFAStatus && userTokenMFA) {
                  console.log('User MFA Status: ', userMFAStatus, 'User Token MFA: ', userTokenMFA);
                  let accessToken = user.signInUserSession.accessToken.jwtToken;
                  this.authService.currentUserSubject.next(user);
                  this.authService.setToken(accessToken);
                  this.authService.initTokenRefresh();
                  this.router.navigate([TOTP_SETUP_PATH]);
                  this.loading = false;
                } else {
                  this.userService.userActive({ aws_cognito_id: user.username }).subscribe((res) => {
                    if (!res?.success) console.log('Error occurred while storing active data: ', res?.errorMessage);
                  });
                  let accessToken = user.signInUserSession.accessToken.jwtToken;
                  this.authService.currentUserSubject.next(user);
                  this.authService.setToken(accessToken);
                  this.authService.initTokenRefresh();
                  this.router.navigate(['/']);
                  this.loading = false;
                }
              })
              .catch((error: any) => {
                console.log('Error: ', error);
                this.router.navigate([LOGIN_PATH]);
                this.loading = false;
              });
          } else if (user?.deleted) {
            this.loading = false;
            this.messageHandlerService.generateToastMessage('error', '', USER_DELETED);
          } else {
            this.loading = false;
            this.messageHandlerService.generateToastMessage('error', '', USER_NOT_ACTIVE);
          }
        } else {
          this.loading = false;
          this.messageHandlerService.generateToastMessage('error', '', USER_NOT_FOUND);
        }
      });
    }
  }

  getReleaseVersion() {
    this.utilService.getReleaseVersion().subscribe((res) => {
      if (res && res.success) {
        if (res.content.data.version !== CURRENT_RELEASE_VERSION) {
          setInterval(() => {
            window.location.reload();
          }, RELOAD_PAGE_AFTER_RELEASE || 5000);
        }
      }
    });
  }

  navigateToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }
}
