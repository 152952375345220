import { Component, OnInit } from '@angular/core';
import {LOGIN_PATH, LOGO_TEXT} from "../common/Constants";
import { Subscription } from "rxjs";
import {Router, ActivatedRoute} from "@angular/router";
import {MessageHandlerService} from "../services/message-handler.service";
import { UserService } from '../services/user.service';
import {AuthService} from "../security/auth.service";
import {CognitoUserService} from "../services/cognito-user.service";

@Component({
  selector: 'app-2fa-verification',
  templateUrl: './verification_2fa.component.html',
  styleUrls: ['./verification_2fa.component.scss']
})
export class TwoFAVerificationComponent implements OnInit {
  logoText = LOGO_TEXT;
  mfaCode: string;
  loading = false;
  subscribers: Subscription[] = [];
  currentUser:any;
  qrcode_url = null;
  onlyVerify = false;
  totpCode = null;

  constructor(private router : Router , private messageHandlerService : MessageHandlerService,
              private userService: UserService, /*private route:ActivatedRoute,*/
              public cognitoUserService:CognitoUserService,
              private authService : AuthService) { }

  ngOnInit(): void {
    // this.route.queryParams.subscribe(param => {
    //   this.onlyVerify = Boolean(param.onlyVerify) ?? false;
    // });

    if(!this.cognitoUserService.cognitoUser?.username){
      this.router.navigate([LOGIN_PATH]);
    }

    if(!this.cognitoUserService.currentUser?.totp_secret) {
      this.getTOTP();
    }
  }

  navigateToPreviousPage() {
    this.router.navigate([LOGIN_PATH]);
  }

 getTOTP() {
    try {
      this.userService.getTotpSecret(this.cognitoUserService.cognitoUser.username).subscribe((res) => {
        if (res && res.qrcode_url) {
          this.qrcode_url = res.qrcode_url;
          this.totpCode = res.totpCode;
        }
      });
    } catch (e) {
      this.messageHandlerService.generateToastMessage("error", "Communication error: ", 'Something went wrong');
    }
  }

  verifyTOTPCode() {
    try{
      this.loading = true;
      this.authService.verifyTOTPCode(this.cognitoUserService.cognitoUser,this.mfaCode).then(res => {
        let accessToken = res.signInUserSession.accessToken.jwtToken;
        this.authService.currentUserSubject.next(res);
        this.authService.setToken(accessToken);
        this.messageHandlerService.generateToastMessage('success','','TOTP code verified successfully');
        this.router.navigate(['']);
        this.loading = false;

      }).catch((error) => {
        this.loading = false;
        this.messageHandlerService.generateToastMessage('error','','Failed to verify TOTP: ' + error.message);
      });
    }catch(error) {
      this.loading = false;
      this.messageHandlerService.generateToastMessage('error', '', 'Failed to verify TOTP: Something went wrong');
    }
  }
}
