import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MessageHandlerService} from "../services/message-handler.service";
import {UserService} from "../services/user.service";
import {UserDataManager} from "../user/UserDataManager";
import {CognitoUserService} from "../services/cognito-user.service";
import {AuthService} from "../security/auth.service";
import {LOGIN_PATH, LOGO_TEXT} from "../common/Constants";
import {Subscription} from "rxjs";
import {User} from "../models/user";

@Component({
  selector: 'app-totp-setup',
  templateUrl: './totp-setup.component.html',
  styleUrls: ['./totp-setup.component.scss']
})
export class TotpSetupComponent implements OnInit, OnDestroy {
  logoText = LOGO_TEXT;
  mfaCode: string;
  loading = false;
  subscriptions: Subscription[] = [];
  currentUser:User;
  qrcode_url = null;
  onlyVerify = false;
  totpCode = null;

  constructor(private router : Router , private messageHandlerService : MessageHandlerService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.setupFirstsTimeTOTP();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  setupFirstsTimeTOTP() {
    try {
        this.userService.generateTotpSecret().subscribe((res) => {
          if (res && res.success) {
            this.qrcode_url = res.content?.data?.qrcode_url;
            this.totpCode = res.content?.data?.totpCode;
          }
        });
    } catch (e) {
      this.messageHandlerService.generateToastMessage("error", "Communication error: ", 'Something went wrong');
    }
  }

  navigateToPreviousPage() {
    this.router.navigate(['/']);
  }

  verifyOneTimeCode() {
    try {
      this.userService.verifyTotpSecret({code: this.mfaCode}).subscribe((res) => {
      if(res && res.success && res.content?.data.is_verified) {
        this.router.navigate(['/']);
        this.messageHandlerService.generateToastMessage("success", "Success: ", "TOTP code verified successfully");
      }else {
        this.messageHandlerService.generateToastMessage("error", "Error: ", "Failed to verify TOTP code: Invalid code");
      }
    });
    }catch (e) {
      this.messageHandlerService.generateToastMessage("error", "Communication error: ", 'Something went wrong');
      throw new Error('Service error thrown');
    }

  }

}
