import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CognitoUserService {
  cognitoUser;
  currentUser;
  constructor() { }



}
